import React, { useEffect, useState } from "react"
import InputMask from 'react-input-mask'
import { SendData } from "../../utils"
import BtnComponent from "../btn-component"
import parse from 'html-react-parser'
import { sendLeadToDataLayer } from "../../utils/send-data"
import { Checkbox } from "../steps/checkbox-item";

const PhoneStep = ({ params, nextStep, resultStep, form, setForm, sendParams, showPolitics, raionsStep, roomsStep, sdachaName }) => {
    const [phone, setPhone] = useState("")
    const [error, setError] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [checkbox, setChecked] = useState(false)
    const [errorChBox, setErrorChBox] = useState(false)
    const urlParams = new URLSearchParams(window.location.search);
    const inputChange = (event) => {
        let value = event.target.value

        if (domainSettings.domain.mask === "") {
            if (value.length > 4 && value.slice(0, 5) != "+7 (9") {
                value = "+7 ("
            }
        }
        setPhone(value)
        setForm({
            ...form,
            phone: value
        })
    }
    const inputSimpleChange = (event) => {
        let value = event.target.value
        setPhone(value)
        setForm({
            ...form,
            phone: value
        })
    }


    useEffect(() => {
        if (form.phone !== "" && form.phone !== undefined) {
            setPhone(form.phone)
        }
    }, [])

    const continueClick = (event) => {
        event.preventDefault()
        if (blocked) {
            return
        }
        if (!checkbox) {
            setErrorChBox(true)
            return
        } else {
            setErrorChBox(false)
        }
        
        if ((domainSettings.domain.mask === "" && phone.length === 18) || (domainSettings.domain.mask !== "" && phone.length === domainSettings.domain.mask.length-1) || (urlParams.has('mask') && phone.length > 8)) {
            setError(false)
            if (domainSettings.domain.validate_phone ) {
                setBlocked(true)
                fetch("/lead/validator/"+form.phone)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        console.log(data);
                        if (data.valid) {
                            SendData(form, setForm, () => nextStep(event), "getForm", false, sendParams)
                        } else {
                            resultStep(event)
                            setBlocked(false)
                            SendData(form, setForm, () => nextStep(event), "getForm", true, sendParams)
                        }
                    });
            } else {
                SendData(form, setForm, () => nextStep(event), "getForm", false, sendParams)
            }
        } else {
            console.log('error')
            let info = sendLeadToDataLayer(form, true) //send error to data layer
            dataLayer.push(info)
            setError(true)
        }
    }
    return <React.Fragment>
        {domainSettings.domain.phone_step_title != "" ? <div className="title_inner">{parse(domainSettings.domain.phone_step_title)}</div> : <div className="title_inner">Подобрано <b style={{ color: `#${params.main_color}` }}>135 вариантов</b> по вашим параметрам. Есть предложения с выгодными акциями и скидками!</div>}
        <div className="block_phone">
            <div className="bp_title">Для получения доступа к базе укажите свой номер телефона</div>
            <div className="bp_inner">
                <form>
                    {urlParams.has('mask')?<input className={error ? "in_phone ym-record-keys err" : "in_phone ym-record-keys"}  onChange={inputSimpleChange} name="phone" value={phone} />:<InputMask mask={domainSettings.domain.mask===""?"+7\ (999) 999-99-99":domainSettings.domain.mask} name="phone" value={phone} onChange={inputChange} maskChar={null} >
                        {(inputProps) => <input className={error ? "in_phone ym-record-keys err" : "in_phone ym-record-keys"}  {...inputProps} placeholder={domainSettings.domain.mask === ""?"+7 ( ___ ) ___ - __ - __":""} />}
                    </InputMask>}
                    <BtnComponent text={"Посмотреть предложения"} params={params} clickFunct={continueClick} analog={true} />
                </form>
            </div>
            <Checkbox params={params} name="tac" checked={checkbox} onChange={() => setChecked(!checkbox)} style={{ display: "flex", }}>
                <span style={{color: errorChBox?"red":"white"}}>я даю согласие на обработку персональных данных <br /> в соответствии с <a onClick={(e)=>{e.preventDefault(); showPolitics()}} href={window.location.host=="top-building-dubai.ru"?"/file-store/policy_top_building_dubai.pdf":"/file-store/policy.pdf"} target="_blank" style={{color: errorChBox?"red":"#FFF"}}>политикой конфиденциальности</a></span>
            </Checkbox>
            <span className="sp_err" id="err_phone"></span>
        </div>
    </React.Fragment>
}

export default PhoneStep