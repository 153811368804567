import React, { useEffect, useState } from "react"
import { GetJKList, generateParamsForUrl, clickLinkDataLayer } from "../../utils/send-data"
import HoveredComponent from "../hovered-component"
import parse from 'html-react-parser'

const ResultStep = ({ params, form, raionsStep, roomsStep, sdachaName, raionsPrice }) => {
    const [jkList, setJkList] = useState([])
    useEffect(async () => {
        if (!domainSettings.domain.showJkList) {
            let jklistPortal = await GetJKList(form)
            setJkList(jklistPortal)
        }
    }, [])
    return (jkList.length == 0 ? <React.Fragment>
        <div className="title_inner"><span style={{ textTransform: "capitalize", display: "inline-block", position: "initial", fontSize: "46px", color: `#${params.main_color}` }}>{form.name}</span>, {domainSettings.domain.result_step_text != "" ? parse(domainSettings.domain.result_step_text) : "в ближайшее время с вами свяжется специалист и уточнит детали для расширенной подборки. Это бесплатно!"}</div>
    </React.Fragment> : <React.Fragment>
        <div className="title_inner">По вашим параметрам наиболее точно подходят квартиры в жилых комплексах</div>
        <ul className="jk_list" id="jk_res_list">
            {jkList.jks.map((jk, index) => index < 4 ? <HoveredComponent params={params} text={jk.name} onClick={() => clickLinkDataLayer(jk.name)} link={`${jkList.url}${jk.code}/${generateParamsForUrl(form)}`} /> : "")}
            <HoveredComponent params={params} text={"Посмотреть каталог полностью"} link={jkList.url?`${jkList.url}${generateParamsForUrl(form)}`:`https://g-n.ru/${generateParamsForUrl(form)}`} />
        </ul>
        <div class="result_bottom_text" style={{ color: `#${params.main_color}` }}>
            <span style={{ textTransform: "capitalize", display: "inline-block", position: "initial" }}>{form.name}</span>, {domainSettings.domain.result_step_text != "" ? parse(domainSettings.domain.result_step_text) : "в ближайшее время с вами свяжется специалист и уточнит детали для расширенной подборки. Это бесплатно!"}
        </div>
    </React.Fragment>
    )
}

export default ResultStep