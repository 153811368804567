import React, { useEffect, useState } from "react"
import BtnComponent from "../btn-component"
import CityTitle from "../city-title"
import parse from 'html-react-parser'
import { startQuizDataLayer } from "../../utils/send-data"
import Cookies from 'js-cookie';

const MainScreen = ({ params, nextStep, showPolitics }) => {
    const [shPolicy, setShPolicy] = useState(true)
    const [cookieTitle, setCookieTitle] = useState(false)

    useEffect(() => {
        let title = Cookies.get('title')
        if (title) {
            while (title.indexOf('+') !== -1) {
                title = title.replace('+', ' ')
            }

        }
        setCookieTitle(title)
    }, [])
    return <React.Fragment>
        <div style={{ display: "block" }} className="page_main">
            
            <div className="page page_1">
                <div className="page_inner">
                    <div className="wmain" >
                        <CityTitle params={params} />
                        {cookieTitle ? <h1>{cookieTitle}</h1> : <h1>{!domainSettings.title || domainSettings.title.length === 0 ? domainSettings.domain.defaultHeader && domainSettings.domain.defaultHeader.length != 0? domainSettings.domain.defaultHeader:"Недорогие новостройки" : parse(domainSettings.title)}</h1>}
                        <div className="sub_title">{params.sub_title === undefined || params.sub_title == "" ? parse("Здесь собрана полная база квартир, более 30 000 вариантов. <br />За 1 минуту вы получите бесплатный доступ к самым выгодным предложениям!") : parse(params.sub_title)}
                            {domainSettings.domain.sub_title_items === undefined || domainSettings.domain.sub_title_items == '' ? "" :
                                <React.Fragment>
                                    <br />
                                    <ul class="header_list">{parse(domainSettings.domain.sub_title_items)}</ul>
                                </React.Fragment>}
                        </div>
                        <BtnComponent text={"Начать быстрый поиск"} params={params} clickFunct={(e) => { startQuizDataLayer(); nextStep(e) }} />
                    </div>
                </div>
                {(domainSettings.domain.politics && shPolicy) ? <div class="cookie"><span>Мы используем файлы cookie, чтобы убедиться, что наш веб-сайт работает должным образом. Вы можете продолжить пользоваться нашим сайтом и принять нашу<a onClick={(e)=>{e.preventDefault(); showPolitics(true);}} href={window.location.host=="nissan.piter-novoctroi.ru"?"/file-store/policy_nissan.piter-novoctroi.pdf":"/file-store/policy.pdf"} target="_blank"> Политику cookie</a>. Изменить ваши настройки cookie можно будет в любой момент.</span><a class="cookie_btn" onClick={(e) => { e.preventDefault(); setShPolicy(false) }}> Я принимаю</a></div> : ""}
            </div>
        </div>
    </React.Fragment>
}

export default MainScreen