import { useEffect, useState } from "react"
import FinishSteps from "../finish-steps"
import MainScreen from "../main-screen"
import Step from "../steps"
import { phoneClickDataLayer } from "../../utils/send-data"
import Politic from "../politic/politic"

const Router = () => {
    const [step, setStep] = useState(null)
    const [params, setParams] = useState({})
    const [sendParams, setSendParams] = useState({
        pipelineId: 0,
        statusId: 0,
        sId: 0,
    })

    const [showPolitic, setShowPolitics] = useState(false)

    const [form, setForm] = useState({})
    const nextStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step===null) {
            setStep(0)
        } else {
            setStep(step + 1)
        }
    }
    const prevStep = (event) => {
        try {
            event.preventDefault()
        } catch (e) { }
        if (step - 1 < 0) {
            setStep(0)
        } else {
            setStep(step - 1)
        }
    }

    useEffect(() => {
        if (domainSettings.domain.yandex != "") {
            try {
                setTimeout(() => {
                    ym(parseInt(domainSettings.domain.yandex), 'getClientID', function (clientID) {
                        domainSettings.domain.clientID = clientID
                    })
                }, 3000)
            } catch (e) { }
        }
        setParams(domainSettings.domain)
    }, [])

    const isMobile = () => {
        let check = false;
        if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
            check = true
        }
        return check;
    }

    const getBackground = () => {
        if (isMobile()) {
            return domainSettings.domain.background_mobile
        }
        return domainSettings.domain.background
    }

    useEffect(()=>{
        if (domainSettings.domain.pageTitle) {
            document.querySelector('title').innerHTML = domainSettings.domain.pageTitle
        }
    },[])

    return <div>
        
        <div className="container_main" style={{ background: params.background != "" ? `url("/file-store/${getBackground() != "" ? getBackground() : params.background}") center / cover no-repeat` : `` }}>
            {showPolitic?<Politic site={domainSettings.domain.url} date={domainSettings.domain.politicsDate!=""?domainSettings.domain.politicsDate:domainSettings.domain.created_at.split(" ")[0]} close={()=>{setShowPolitics(false)}}/>:<></>}
            {/*<section className="hl_top">
                <div className="wmain">
                    <div className="logo">
                        <img style={{ height: 'auto', opacity: "0" }} src="/templates/plans_template/build/img/logo.png" />
                    </div>
                    <div className="hl_phone">
                        <a
                            className="roistat"
                            onClick={(e) => {}}
                            href={
                                params.phone
                                    ? 'tel:' + params.phone
                                    : 'tel:+78006005523'
                            }
                        >
                            {params.phone ? params.phone : '8 800 600 55 23'}
                        </a>
                    </div>
                </div>
            </section>*/}
            {(() => {
                switch (true) {
                    case step === null:
                        return <MainScreen params={params} nextStep={nextStep} showPolitics={()=>{setShowPolitics(true)}} />
                    case params.steps.length <= step:
                        return <FinishSteps form={form} sendParams={sendParams} setForm={setForm} params={params} showPolitics={()=>{setShowPolitics(true)}} />;
                    default:
                        return <Step step={params.steps[step]} params={params} sendParams={sendParams} setSendParams={setSendParams} index={step} length={params.steps.length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} />;
                }
            })()}
        </div>
        <div className="about_company">
            <div className="ac__content">
                <div className="ac__left">
                    {domainSettings.organization.name}, <br />
                    {domainSettings.organization.adress}
                </div>
                <div className="ac__right">
                    {domainSettings.organization.phone.toLowerCase() !== "x" ? <a href={"tel:" + domainSettings.organization.phone} onClick={(e) => phoneClickDataLayer(domainSettings.organization.phone)}>{domainSettings.organization.phone}</a>:<></>}
                </div>
            </div>
        </div>
    </div>

}

export default Router