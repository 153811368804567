import React, { useState, useEffect } from "react"
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import { GetJKList } from "../../utils/send-data";
import CheckBoxItem from "../steps/checkbox-item";
import BtnComponent from "../btn-component";

const JkListStep = ({ params, nextStep, form, setForm, }) => {
    const [jkList, setJkList] = useState([])
    const [pickedJk, setPickedJk] = useState()
    useEffect(async () => {
        let jklistPortal = await GetJKList(form)
        if (jklistPortal.jks.length === 0) {
            let jkList = []
            domainSettings.domain.jkList.forEach((jk)=>{
                let jkInfo = {
                    id: jk.id,
                    name: jk.name,
                    code: jk.code,
                }
                jkList.push(jkInfo)
            })
            setJkList(jkList)
            return
        }
        setJkList(jklistPortal.jks)
    }, [])

    useEffect(()=>{
        if (pickedJk){
            setForm({
                ...form,
                pipeline_id: 4444414, 
                status_id: 41190232,
                jk_id: pickedJk.value,
                jk_name: pickedJk.name,
            })
        }
    },[pickedJk])

    const continueClick = (event) =>{
        event.preventDefault()
        if (pickedJk) {
            nextStep(event)
        }
    }

    return <React.Fragment>
        <div className="title_inner">По вашим параметрам наиболее точно подходят квартиры в жилых комплексах</div>
        <SimpleBarReact style={{ maxHeight: 300 }} autoHide={false}>
            <ul className="check_list_one check_list" id="kv_list">
                {jkList.map((jk, index)=>{
                    console.log(jk)
                    return <CheckBoxItem key={index} checked={pickedJk && pickedJk.value === jk.id} updateCheck={setPickedJk} params={params} answer={{title:jk.name, value: jk.id}} />
                })}
            </ul>
        </SimpleBarReact>
        <div className="btn_block">
            <BtnComponent text={"Узнать подробнее"} params={params} clickFunct={continueClick} analog={true} />
        </div>
    </React.Fragment>
}

export default JkListStep