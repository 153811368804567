import React, { useEffect, useState } from "react"
import CityTitle from "../city-title"
import BtnComponent from "../btn-component";
import SliderStep from "./slider-step";
import TextStep from "./text-step";
import axios from "axios";
import { stepChangeDataLayer } from "../../utils/send-data";
import { QuestionType } from "./enums";

const Step = ({ step, params, sendParams, setSendParams, index, length, nextStep, prevStep, raionsStep, roomsStep, form, setForm }) => {
    const [prices, setPrices] = useState({min_value:3000000, max_value:15000000})
    const backBtn = (index) => {
        switch (index) {
            case 0:
                return ""
            case 1:
                return <BtnComponent text={"Назад"} params={params} clickFunct={(e) => {stepChangeDataLayer(false, index+1); prevStep(e)}} />
            default:
                return <BtnComponent text={"Назад"} params={params} clickFunct={(e) => {stepChangeDataLayer(false, index+1); prevStep(e)}} />
        }
    }

    useEffect(async ()=>{
        let response = await axios.get("/tmp_prices/"+domainSettings.city.ID)
        setPrices(response.data.payload)
    }, [])

    
    return (<div style={{ display: "block" }} className="page_main pages ">
        <div className="page">
            <div className="page_inner">
                <div className="wmain">
                    <CityTitle params={params} index={index} length={length} />
                    <div className="title_inner title_room">{step.title}</div>
                    {(() => {
                        switch (step.questionType) {
                            case QuestionType.SimpleQuestion:
                            case QuestionType.RaionsQuestion:
                            case QuestionType.RoomsQuestion:
                            case QuestionType.PriceQuestionVariants:
                            case QuestionType.CompletionQuestion:
                            case QuestionType.ClassQuestion:
                            case QuestionType.DistrictQuestion:
                                return <TextStep sendParams={sendParams} step={step} params={params} index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} setSendParams={setSendParams} />
                            case QuestionType.PriceQuestionSlider:
                                return <SliderStep step={step} raionsStep={raionsStep} roomsStep={roomsStep} params={params} index={index} length={length} nextStep={nextStep} prevStep={prevStep} form={form} setForm={setForm} setSendParams={setSendParams} />
                        }
                    })()}
                    <div className="btn_block">
                        {backBtn(index)}
                        <BtnComponent text={"Дальше"} params={params} clickFunct={(e) => {stepChangeDataLayer(true, index+1); nextStep(e)}} />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}





export default Step